import React, { useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  Modal,
  Button,
  TextField,
  styled,
} from '@mui/material';
import user from '../../api/user';
import { useTranslation } from 'react-i18next';
import Compdirection from '../Common/CompDirection';
import BoxDirection from '../Common/BoxDirection';
import { CustomToast } from '../../common/Methodcommon';

const StyledTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#7C0E6F',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#7C0E6F',
    color: '#7C0E6F',
  },
  '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    font: 'normal normal normal 14px/24px',
  },
  '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
    fontSize: '0.85em',
  },
});

const ChangePasswordModal = (props) => {
  const { t } = useTranslation();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const handleSubmit = async () => {
    let isFormValid = true;
    if (!currentPassword || !newPassword || !confirmPassword) {
      isFormValid = false;
      CustomToast('error', t('Please fill all required fields!'));
    }
    if (currentPassword && newPassword && newPassword === currentPassword) {
      isFormValid = false;
      CustomToast('error', t('Current & New Password cannot be same!'));
    }

    if (isFormValid) {
      if (newPassword === confirmPassword) {
        const payload = {
          user_name: props?.username,
          password: newPassword,
          current_password: currentPassword,
          reset_token: props?.resetToken,
        };

        user
          .resetPasswordByUsername(payload)
          .then((res) => {
            if (res.response === 'success') {
              CustomToast('success', res?.data);
              props.closeModal();
            }
          })
          .catch((err) => {
            CustomToast(
              'error',
              err?.response?.data?.message || err?.response?.data?.data,
            );
          });
      } else {
        CustomToast('error', t('New & Confirm Password should be same!'));
      }
    }
  };

  const validatePassword = (inputPassword) => {
    // Define a regular expression pattern for the password format
    const passwordPattern =
      /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9a-zA-Z]).{8,}$/;

    if (!passwordPattern.test(inputPassword)) {
      setPasswordError(
        t(
          'Password should be: Uppercase, one special character, and Alphanumeric, minimum 8 characters.',
        ),
      );
      return false;
    } else {
      setPasswordError('');
      return true;
    }
  };

  return (
    <Modal open={props.isOpen} onClose={props.closeModal}>
      <Box
        style={{
          height: 'auto',
          width: '426px',
          padding: '1.75em',
          borderRadius: '0.938em',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: '#ffffff 0% 0% no-repeat padding-box',
          outline: 'none',
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            textAlign: 'center',
          }}
        >
          <Box
            style={{
              backgroundColor: '#FFF7E5',
              padding: '10px',
              width: 'auto',
              height: 'auto',
            }}
          >
            <Typography
              style={{
                fontSize: '12px',
                color: '#FFB400',
              }}
            >
              {props?.showPasswordExipry
                ? t('Your Password has been expired.')
                : t('Please update strong password.')}
            </Typography>
          </Box>
        </Grid>
        <BoxDirection>
          <Grid
            container
            spacing={2}
            style={{
              marginTop: '10px',
            }}
          >
            <Grid item xs={12}>
              <Compdirection>
                <StyledTextField
                  type={showCurrentPassword ? 'text' : 'password'}
                  label={t('Current Password')}
                  value={currentPassword}
                  fullWidth={true}
                  onChange={(e) => {
                    setCurrentPassword(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <i
                        className={`las ${showCurrentPassword ? 'la-eye-slash' : 'la-eye'} text-[#7C0E6F] text-xl`}
                        onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                        style={{ cursor: 'pointer' }}
                      ></i>
                    )
                  }}
                />
              </Compdirection>
            </Grid>
            <Grid item xs={12}>
              <Compdirection>
                <StyledTextField
                  type={showNewPassword ? 'text' : 'password'}
                  label={t('New Password')}
                  value={newPassword}
                  fullWidth={true}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    validatePassword(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <i
                        className={`las ${showNewPassword ? 'la-eye-slash' : 'la-eye'} text-[#7C0E6F] text-xl`}
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        style={{ cursor: 'pointer' }}
                      ></i>
                    )
                  }} />
              </Compdirection>
            </Grid>
            {/* Display error message */}
            {passwordError && (
              <Grid item xs={12}>
                <Typography style={{ color: 'red', fontSize: '14px' }}>
                  {passwordError}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Compdirection>
                <StyledTextField
                  type={showNewPassword ? 'text' : 'password'}
                  label={t('Confirm New Password')}
                  value={confirmPassword}
                  fullWidth={true}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <i
                        className={`las ${showNewPassword ? 'la-eye-slash' : 'la-eye'} text-[#7C0E6F] text-xl`}
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        style={{ cursor: 'pointer' }}
                      ></i>
                    )
                  }}
                />
              </Compdirection>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={() => handleSubmit()}
                style={{
                  backgroundColor: passwordError ? '#e5e7eb' : '#7C0E6F',
                  borderRadius: '0.3em',
                  color: 'white',
                  width: '280px',
                }}
                disabled={passwordError ? true : false}
              >
                {t('Reset Password')}
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                textAlign: 'center',
              }}
            >
              <Typography
                style={{
                  color: '#9E9E9E',
                  cursor: 'pointer',
                }}
                onClick={props.closeModal}
              >
                {t('Back to Login')}
              </Typography>
            </Grid>
          </Grid>
        </BoxDirection>
      </Box>
    </Modal>
  );
};
export default ChangePasswordModal;

export const salaryDays = [
  { label: '30 Days', value: '30', id: 30 },
  { label: 'Calender Days', value: 'calenderDays', id: 0 },
];

export const exitReasons = [
  { label: 'Termination', value: 'termination' },
  { label: 'Resignation', value: 'resignation' },
];

export const INPUT_TYPES = {
  boolean: 'Enable',
  string: 'Text',
};

export const ROLE_SEQUENSE_TYPE = {
  isDirectManager: '1',
  rootManager: '2'
};

export const CONFIG_ID = {
  employeeType : 1,
  employeeCategory : 2,
  document : 3,
  leaveType : 4,
  benefits : 5,
  timeshift : 6,
  weekoff : 8,
  grade : 9,
  payrollType : 10,
  assetType : 13,
  insurerType : 14,
  awardType : 15,
  contractType : 16,
  insurerPolicyType : 17,
  gender : 18,
  maritalStatus : 19,
  relationship : 20,
  ticketFrequency : 21,
  trainingType : 22,
  additionalBenefits : 23,
  loanType : 24,
  deductionType : 25,
  additionType : 26,
  claimType : 27,
  salaryConfiguration : 28,
  exitReason : 29, 
  exitPolicy : 30,
  leaveFnf : 31,
  payoutConfiguration : 32,
  empBankExchangeConfiguration : 33,
  compBankExchangeConfiguration : 34,
  ticketType : 35,
  notification : 36,
  leaveAccrualPolicy : 37,
  leaveAdvancePolicy : 38,
  leaveEncashMentPloicy : 39,
  passwordPolicy : 40,
  mfa : 41,
  roleSeq : 42,
  notiActions : 43,
  calenderDay : 44,
  attendanceExceptions : 45,
  preBoardingDocumentType : 46,
  preBoardingTask : 47,
  onBoardingTask : 48
}

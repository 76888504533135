import _ from 'lodash';
import { authz as auth } from '../api/authz';
export const authz = {
  fetchUserContextService,
  switchBU,
  switchLanguage,
  isActiveCompany,
};

/**
 *
 * @param {String} user_id
 */
function fetchUserContextService(user_id) {
  return new Promise((resolve, reject) => {
    auth
      .getUserContext(user_id)
      .then((result) => {
        const records = _.map(result, function (role) {
          var translateddata = role?.company_translation;
          if (translateddata && translateddata?.length > 0) {
            var arabicdata = translateddata?.filter(
              (e) =>
                e?.column_name === 'company_name' &&
                e?.table_name === 'companies',
            )[0];
            if (arabicdata) {
              role['company_name_ar'] = arabicdata['ar'];
              role['company_name'] = arabicdata['en'];
            }
          }
          const row = {
            emp_id: role.emp_id,
            defaultBU: role.default_BU,
            user_id: role.user_id,
            user_details: role.user,
            user_preferences: role.user_preferences,
            company_id: role.company_id,
            company_setting: role.company_setting,
            company_name: role.company_name,
            company_name_ar: role?.company_name_ar,
            role_name: role.role_info.role_name,
            role_name_ar: role.role_info?.role_name_ar,
            role_id: role.role_info.role_id,
            acl: role.access_control_list,
            company_assigned_modules: role?.company_assigned_modules,

            screen_access: _.groupBy(role.access_control_list, 'screen_name'),
          };
          return row;
        });

        /**
         * Make Company based access control list
         * Combine all screens that are available as part of roles in a company
         */

        let companyWise = _.groupBy(records, 'company_id');
        let aclByCompany = {};
        _.forEach(companyWise, function (roleRecords, company_id) {
          const acls = _.uniqBy(
            _.flattenDeep(_.map(roleRecords, 'acl')),
            'perm_name',
          );
          const assigned__modules = _.map(
            roleRecords,
            'company_assigned_modules',
          );
          // const assigned__screens = _.map(
          //     roleRecords,
          //     "company_assigned_screens"
          // );
          const r = _.map(roleRecords, 'role_name');
          const r_ar = _.map(roleRecords, 'role_name_ar');
          const setting = roleRecords[0].company_setting;

          aclByCompany[company_id] = {
            company_assigned_modules: assigned__modules,
            //company_assigned_screens: assigned__screens,
            perms: acls,
            roles: r,
            roles_ar: r_ar,
            settings: setting,
            company_name: roleRecords[0].company_name,
            company_name_ar: roleRecords[0].company_name_ar,
          };
        });

        /**
         * Always Set Employee as Default Role.
         * If super Admin, set default as super admin.
         *
         */
        let defaultRole = records[0];
        let is_admin = false;
        const adminRole = _.find(records, function (r) {
          return r.role_id === 3 && r.role_name === 'Super Admin';
        });
        if (adminRole) {
          defaultRole = adminRole;
          is_admin = true;
        }

        const acl = {
          currentRole: defaultRole,
          isAdmin: is_admin,
          access_control: records,
          company_wise_access: aclByCompany,
          company_wise_records: companyWise,
        };

        return resolve(acl);
      })
      .catch((err) => {
        console.log('ERROR', err);
        return reject(err);
      });
  });
}
/**
 *
 * @param {String} user_id
 * @param {String} company_id
 * @returns
 */
function switchBU(user_id, company_id) {
  return new Promise((resolve, reject) => {
    auth
      .switchBU(user_id, company_id)
      .then((response) => {
        return resolve(response);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function isActiveCompany(company_id) {
  return new Promise((resolve, reject) => {
    auth
      .isActiveCompany(company_id)
      .then((response) => {
        return resolve(response);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

/**
 *
 * @param {String} userId
 * @param {String} lang
 * @returns
 */
function switchLanguage(userId, lang) {
  return new Promise((resolve, reject) => {
    auth
      .switchlanguage(userId, lang)
      .then((response) => {
        return resolve(response);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

import { Tooltip } from '@mui/material';
import _ from "lodash"
import { useTranslate } from '../../../hooks';

function InfoCell({ value, name, show }) {
    const { translate } = useTranslate()
    return (
        <Tooltip
            slotProps={{
                tooltip: {
                    className: '!bg-white !min-w-[130px]',
                },
            }}
            title={
                <div className={`flex flex-col gap-[4px] text-black text-nowrap`}>
                    {translate(`${name} :`)} <br />
                    {value?.length > 0 ? (
                        _.map(value, (item, index) => (
                            <span key={item.id}>
                                {item?.name}
                                {index < value?.length - 1 ? ', ' : '. '}
                            </span>
                        ))
                    ) : (
                        translate(`No ${name}`)
                    )}
                </div>

            }
        >

            <div className="flex justify-between max-w-[130px]">
                {show === 1 && (
                    <p className="text-xs text-text italic font-semibold">
                        {value?.length > 0 && translate(value[0]?.name)}...
                    </p>
                )}
                <i class="las la-info-circle table-icon cursor-pointer text-secondary mt-[0.1em]"></i>
            </div>

        </Tooltip>
    )
}

export default InfoCell;

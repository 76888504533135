
export const payloadValidator = (file) =>{
    const allowedDocTypes = ['application/pdf', 'application/msword'];
    const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg'];
    const allowedSize = 3.2 * 1024 * 1024;
    if (!file) {
        return {isValid: false, message: 'File is required'};
    }
    if (allowedDocTypes.includes(file?.type)) {
        if (file?.size <= allowedSize) return {isValid: true};
        else return {isValid: false, message: 'File size exceeds 3MB limit.'};
    } else if (allowedImageTypes.includes(file?.type)) {
        if (file?.size <= allowedSize) return {isValid: true};
        else return {isValid: false, message: 'File size exceeds 3MB limit.'};
    } else {
        return {isValid: false, message: 'Invalid file type'};
    }
}

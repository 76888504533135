import Echo from 'laravel-echo';

function createSocketConnection() {
    if (!window.Echo) {
        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: process.env.REACT_APP_WEBSOCKET_KEY,
            cluster: process.env.REACT_APP_WEBSOCKET_CLUSTER,
            wsHost: process.env.REACT_APP_WEBSOCKET_URL,
            forceTLS: false,
            wsPort: process.env.REACT_APP_WEBSOCKET_PORT,
            wssPort: process.env.REACT_APP_WEBSOCKET_PORT,
            encrypted: false,
            transports: ['websocket', 'polling', 'flashsocket'],
            enabledTransports: ['ws', 'wss'],
        });
    }
}

export default createSocketConnection;

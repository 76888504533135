import React, { useState } from 'react';
import { Grid, Box, Typography, TextField, styled } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import user from '../../api/user';
import { useTranslation } from 'react-i18next';
import Compdirection from '../Common/CompDirection';
import BoxDirection from '../Common/BoxDirection';
import { Button } from '../../components';
import { useNavigate } from 'react-router';
const StyledTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#7C0E6F',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#7C0E6F',
    color: '#7C0E6F',
  },
  '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
    font: 'normal normal normal 14px/24px',
  },
  '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
    fontSize: '0.85em',
  },
});

function ResetPassword() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [snackbarMsg, setSnackbarMsg] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (new_password, confirm_password) => {
    if (new_password === confirm_password) {
      const queryString = window.location.search;
      let token = null;

      if (queryString) {
        const urlParams = new URLSearchParams(queryString);
        token = urlParams.get('token');
        if (token) {
          await user
            .verify_user_reset_token(token)
            .then((res) => {
              if (res?.response === 'success') {
                if (res?.data?.username) {
                  const payload = {
                    user_name: res?.data?.username,
                    token: token,
                    password: confirm_password,
                  };
                  user
                    .reset_password_with_reset_token(payload)
                    .then((res) => {
                      setSnackbarMsg(res.data);
                      setTimeout(() => navigate("/login", { replace: true }), 1000);
                    })
                    .catch((err) => {
                      setSnackbarMsg(err?.response?.data?.data);
                      console.error(err?.data);
                    });
                } else {
                  setSnackbarMsg('Something went wrong');
                }
              }
            })
            .catch((err) => {
              console.error(err);
              setSnackbarMsg(err?.response?.data?.data);
            });
        } else {
          setSnackbarMsg('Something went wrong');
        }
      }
    } else {
      setSnackbarMsg('Password and Confirm password do not match');
    }
  };
  const validatePassword = (inputPassword) => {
    // Define a regular expression pattern for the password format
    const passwordPattern =
      /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9a-zA-Z]).{8,}$/;

    if (!passwordPattern.test(inputPassword)) {
      setPasswordError(
        'Password should be: Uppercase, one special character, and Alphanumeric, minimum 8 characters.',
      );
      return false;
    } else {
      setPasswordError('');
      return true;
    }
  };

  return (
    <Box
      style={{
        height: 'auto',
        width: '426px',
        padding: '1.75em',
        borderRadius: '0.938em',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: 'white',
        outline: 'none',
      }}
    >
      <BoxDirection>
        <Grid
          container
          spacing={2}
          style={{
            marginTop: '10px',
          }}
        >
          <Grid item xs={12}>
            <Compdirection>
              <StyledTextField
                type={showPassword ? 'text' : 'password'}
                label={t('New Password')}
                value={newPassword}
                fullWidth={true}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                  validatePassword(e.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <i
                      className={`las ${showPassword ? 'la-eye-slash' : 'la-eye'} text-[#7C0E6F] text-xl`}
                      onClick={() => setShowPassword(!showPassword)}
                      style={{ cursor: 'pointer' }}
                    ></i>
                  ),
                }}
              />
            </Compdirection>
          </Grid>
          {passwordError && (
            <Grid item xs={12}>
              <Typography style={{ color: 'red' }}>{passwordError}</Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Compdirection>
              <StyledTextField
                type={showPassword ? 'text' : 'password'}
                label={t('Confirm New Password')}
                value={confirmPassword}
                fullWidth={true}
                onChange={(e) => setConfirmPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <i
                      className={`las ${showPassword ? 'la-eye-slash' : 'la-eye'} text-[#7C0E6F] text-xl`}
                      onClick={() => setShowPassword(!showPassword)}
                      style={{ cursor: 'pointer' }}
                    ></i>
                  ),
                }}
              />
            </Compdirection>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={() => handleSubmit(newPassword, confirmPassword)}
              disabledClassName="disabled-btn w-full"
              className="primary-btn w-full"
              disabled={passwordError ? true : false}
            >
              {t('Reset Password')}
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              textAlign: 'center',
            }}
          >
            <Typography
              style={{
                color: '#9E9E9E',
                cursor: 'pointer',
              }}
              onClick={() => (window.location.href = '/login')}
            >
              {t('Back to Login')}
            </Typography>
          </Grid>
        </Grid>
      </BoxDirection>
      <Snackbar
        open={!!snackbarMsg}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        autoHideDuration={4000}
        onClose={() => setSnackbarMsg(null)}
      >
        <SnackbarContent
          message={snackbarMsg}
          style={{
            background: '#2475cd',
          }}
        />
      </Snackbar>
    </Box>
  );
}
export default ResetPassword;

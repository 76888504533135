import api from './core';
import browserTimeZone from '../common/localTime';

const organisation = {
  getDepartments,
  getSuggestedDepts,
  addDepartment,
  deleteDepartment,
  deleteAllDepartment,
  editDepartment,
  getDesignations,
  getSuggestedDesigs,
  addDesignation,
  deleteDesignation,
  editDesignation,
  getLocations,
  addLocation,
  editLocation,
  deleteLocation,
  getRoles,
  getSuggestedRoles,
  getPermissions,
  addSubsidiary,
  getSubsidiaries,
  addRole,
  editRole,
  deleteRole,
  getConfigs,
  getConfigTypeById,
  addConfigType,
  editConfigType,
  deleteConfigType,
  getOrgSetUpProgress,
  addRolesBulk,
  getPermissionsByRoleId,
  addAllDepartment,
  getRolesAndPermissions,
  getCompanyInsurer,
  getConfigSuggestedList,
  addRuleEngine,
  canAddConfig,
  getAllConfigs,
  getEmployeeByRole,
  deleteAllDesignation,
  getConfigsEligibleForRules,
  addRule,
  getConfigType,
  addRuleToConfigType,
  getRuleForType,
  addStepSettings,
  updateStepSettings,
  getStepSettings,
  addRuleToStep,
  addParentToStep,
  getRuleForStep,
  getModelNameForType,
  updateRule,
  deleteRule,
  submitApplicationHr,
  getConfig,
  terminationTypes,
  resignationTypes,
  editStatus,
  getAllSettings,
  resignationReasons,
  terminationReasons,
  endOfContractReasons,
  // getSalaryConfigs,
  getMyNotifications,
  markRead,
  exportAll,
  gratuityCalculator,
  getEmpContractTypes,
  getAllEmpNotifications,
  getAttachedFile,
  getPayoutEntityTypes,
  getConfigData,
  readMyNotifications,
  getPayoutConfigurationTypes,
  getBankNameList,
  UpdateViewAllReadAt,
  getAvailableNotificationChannels,
  enableChannel,
  disableChannel,
  getAvailableNotificationActions,
  enableActions,
  disableAction,
  getRoleSequence,
  addRoleSequences,
  getRoleSequencesConfigs,
  deleteSequence,
  getNotiifcationActionConfigList,
  getnotifcationactionPermission,
  enableAllIsDirectmanager,
  getMfaOptionList,
  getMfaConfigList,
  addMfaConfigs,
  deleteMfaConfig,
};
function enableAllIsDirectmanager(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(`/v2/enableAllIsDirectmanager`, payload)
      .then((result) => {
        return resolve(result?.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getnotifcationactionPermission(company_id, role_id) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v2/notifcationactionPermission?company_id=${company_id}&role_id=${role_id}`,
      )
      .then((result) => {
        return resolve(result?.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getNotiifcationActionConfigList(company_id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v2/notificationActionConfigList?company_id=${company_id}`)
      .then((result) => {
        return resolve(result?.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function deleteSequence(roleID, CompanyId) {
  return new Promise((resolve, reject) => {
    api
      .delete(`/v2/deleteRoleSequences?roleID=${roleID}&CompanyId=${CompanyId}`)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getRoleSequencesConfigs(company_id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v2/RoleSequencesConfigs?company_id=${company_id}`)
      .then((result) => {
        return resolve(result?.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function addRoleSequences(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(
        `/v2/addRoleSequences?locale=${localStorage.getItem('applang')}`,
        payload,
      )
      .then((result) => {
        return resolve(result?.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getRoleSequence(company_id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v2/rolesequence?company_id=${company_id}`)
      .then((result) => {
        return resolve(result?.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getAvailableNotificationChannels(company_id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/notifications/channels/stats?company_id=${company_id}`)
      .then((result) => {
        return resolve(result);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getAvailableNotificationActions(company_id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/notifications/actions/stats?company_id=${company_id}`)
      .then((result) => {
        return resolve(result);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function enableChannel(company_id, channel_id) {
  const payload = {
    company_id: company_id,
    channel_id: channel_id,
  };
  return new Promise((resolve, reject) => {
    api
      .post(`/v1/notifications/channel/enable`, payload)
      .then((result) => {
        return resolve(result);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function enableActions(company_id, action_id) {
  const payload = {
    company_id: company_id,
    action_id: action_id,
  };
  return new Promise((resolve, reject) => {
    api
      .post(`/v1/notifications/action/enable`, payload)
      .then((result) => {
        return resolve(result);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function disableChannel(company_id, channel_id) {
  const payload = {
    company_id: company_id,
    channel_id: channel_id,
  };
  return new Promise((resolve, reject) => {
    api
      .post(`/v1/notifications/channel/disable`, payload)
      .then((result) => {
        return resolve(result);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function disableAction(company_id, action_id) {
  const payload = {
    company_id: company_id,
    action_id: action_id,
  };
  return new Promise((resolve, reject) => {
    api
      .post(`/v1/notifications/action/disable`, payload)
      .then((result) => {
        return resolve(result);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function UpdateViewAllReadAt(UserID, CompanyID) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/notification/updateViewAllreadat?userId=${UserID}&companyId=${CompanyID}`,
      )
      .then((result) => {
        return resolve(result);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getBankNameList(companyId, payoutypeID) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/getBankNameList?companyID=${companyId}&locale=${localStorage.getItem(
          'applang',
        )}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getAttachedFile(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/downloadAnnouncement/${id}`)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function getPayoutConfigurationTypes() {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/config/payout/types?locale=${localStorage.getItem('applang')}`)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function getAllSettings(companyId) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/settings/list?companyId=${companyId}&locale=${localStorage.getItem(
          'applang',
        )}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function getMyNotifications(uid, companyId) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/notifications/get?company_id=${companyId}&user_uid=${uid}&locale=${localStorage.getItem(
          'applang',
        )}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function getAllEmpNotifications(uid, companyId) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/allNotifications/get?company_id=${companyId}&user_uid=${uid}&locale=${localStorage.getItem(
          'applang',
        )}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function exportAll(payload, params = {}) {
  const today = new Date();
  let hour = today.getHours();
  if (hour < 10) hour = '0' + hour;
  let mins = today.getMinutes();
  if (mins < 10) mins = '0' + mins;
  let client_time = hour.toString() + mins.toString();
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/export?company_id=${payload.company_id}&event=${
          payload.event
        }&user=${payload.user_id}&emp_id=${payload.emp_id}&period_id=${
          payload.period_id
        }&template_id=${payload.template_id}&payroll_cycle_id=${
          payload.payroll_cycle_id
        }&target_date=${payload.target_date}&training_id=${
          payload.training_id
        }&from_date=${payload.from_date}&to_date=${
          payload.to_date
        }&training_id=${payload.training_id}&locale=${localStorage.getItem(
          'applang',
        )}&client_time=${client_time}&timeZone=${browserTimeZone}&app=1`,
        {
          params: params ? params : '',
        },
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function markRead(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/notifications/mark_read/${id}`)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function editStatus(id, status) {
  return new Promise((resolve, reject) => {
    api
      .put(`/v1/company/setStatus`, {
        company_id: id,
        is_active: status,
      })
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function canAddConfig(existing, next) {
  const addFiltered = existing?.filter(
    (e) =>
      e.name?.trim()?.toLowerCase() === next?.name?.trim()?.toLowerCase() &&
      e.department_id === next?.department_id &&
      (next?.id ? `${next?.id}` !== `${e.id}` : true),
  );
  return addFiltered?.length > 0 ? false : true;
}

function getRuleForStep(stepId) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/machine/getRuleByStepId/${stepId}`)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function resignationTypes() {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/resignations/types?locale=${localStorage.getItem('applang')}`)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function terminationTypes() {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/terminations/types`)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getEmpContractTypes(companyID, empId) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/Empcontracttypes?companyId=${companyID}&empId=${empId}&locale=${localStorage.getItem(
          'applang',
        )}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function resignationReasons() {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/resignations/reasons?locale=${localStorage.getItem('applang')}`)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function terminationReasons() {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/terminations/reasons?locale=${localStorage.getItem('applang')}`)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function endOfContractReasons() {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/endOfContract/reasons`)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function addRule(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(`/v1/engine/rule`, payload)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function submitApplicationHr(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(`/v1/hrAdmin/create`, payload)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function updateRule(payload, id) {
  return new Promise((resolve, reject) => {
    api
      .put(`/v1/engine/rule/${id}`, payload)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function deleteRule(id) {
  return new Promise((resolve, reject) => {
    api
      .delete(`/v1/engine/rule/${id}`)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function addRuleToStep(payload) {
  return new Promise((resolve, reject) => {
    api
      .put(`/v1/machine/addRuleToStep`, payload)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function addParentToStep(payload) {
  return new Promise((resolve, reject) => {
    api
      .put(`/v1/machine/addParentToStep`, payload)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function addStepSettings(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(`/v1/machine/settings/step`, payload)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function getStepSettings(stepId) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/machine/settings/step/${stepId}`)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function updateStepSettings(payload, stepId) {
  return new Promise((resolve, reject) => {
    api
      .put(`/v1/machine/settings/step/${stepId}`, payload)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function addRuleToConfigType(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(`/v1/engine/attach/rule`, payload)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function getEmployeeByRole(companyId, roleId, isDirect, locale = 'en') {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/getEmployeesByRole?companyId=${companyId}&roleId=${roleId}&isDirect=${isDirect}&locale=${locale}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function gratuityCalculator(arg) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/fnf/gratuityCalculator?company_id=${arg.company_id}&hire_date=${arg.hire_date}&exit_date=${arg.exit_date}&emp_salary=${arg.emp_salary}&unauthorized_leaves=${arg.unauthorized_leaves}&exit_type=${arg.exit_type}&exit_reason=${arg.exit_reason}&contract_type=${arg.contract_type}&gratuity_day_per_day=${arg.gratuity_day_per_day}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function getConfigSuggestedList(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/config/suggestedTypes?`, {
        params: {
          id: id,
          locale: localStorage.getItem('applang'),
        },
      })
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function getCompanyInsurer(companyId) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/getInsurers?locale=${localStorage.getItem('applang')}`, {
        params: {
          companyId: companyId,
        },
      })
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
// function getSalaryConfigs(companyId) {
//     return new Promise((resolve, reject) => {
//         api.get(``, {
//             params: {
//                 companyId: companyId,
//             },
//         })
//             .then((result) => {
//                 return resolve(result.data.data);
//             })
//             .catch((err) => {
//                 return reject(err);
//             });
//     });
// }
function getDesignations(companyId) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/designation/select?locale=${localStorage.getItem('applang')}`, {
        params: {
          companyId: companyId,
        },
      })
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function getPermissionsByRoleId(roleId) {
  return new Promise((resolve, reject) => {
    api
      .get(`v1/permissions/getByRole/${roleId}`)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getPayoutEntityTypes() {
  return new Promise((resolve, reject) => {
    api
      .get(`v1/payoutEntities/list?locale=${localStorage.getItem('applang')}`)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function getDepartments(companyId) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/department/select?locale=${localStorage.getItem('applang')}`, {
        params: {
          companyId: companyId,
        },
      })
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function getOrgSetUpProgress() {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/organisationProgress`)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getSuggestedDepts(type) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/getSuggested?locale=${localStorage.getItem('applang')}`, {
        params: {
          type: type,
        },
      })
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function addDepartment(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(
        `/v1/department/insert?locale=${localStorage.getItem('applang')}`,
        payload,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function addAllDepartment(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(`/v1/department/insertBulk`, payload)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function deleteDepartment(payload) {
  return new Promise((resolve, reject) => {
    api
      .delete(
        `/v1/department/delete?locale=${localStorage.getItem('applang')}`,
        {
          data: payload,
        },
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function deleteAllDepartment(payload) {
  return new Promise((resolve, reject) => {
    api
      .delete(`/v1/department/deleteBulk`, {
        data: payload,
      })
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function editDepartment(payload) {
  return new Promise((resolve, reject) => {
    api
      .put(
        `/v1/department/update?locale=${localStorage.getItem('applang')}`,
        payload,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function getSuggestedDesigs(type) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/suggestedDesignations`, {
        params: {
          type: type,
        },
      })
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function addDesignation(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(
        `/v1/designation/insert?locale=${localStorage.getItem('applang')}`,
        payload,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function deleteDesignation(payload) {
  return new Promise((resolve, reject) => {
    api
      .delete(
        `/v1/designation/delete?locale=${localStorage.getItem('applang')}`,
        {
          data: payload,
        },
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function deleteAllDesignation(payload) {
  return new Promise((resolve, reject) => {
    api
      .delete(`/v1/designation/deleteBulk`, { data: payload })
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function editDesignation(payload) {
  return new Promise((resolve, reject) => {
    api
      .put(
        `/v1/designation/update?locale=${localStorage.getItem('applang')}`,
        payload,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getLocations(companyId) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/location/selectLocation`, {
        params: {
          companyId: companyId,
        },
      })
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function addLocation(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(`/v1/location/insert`, payload)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function deleteLocation(payload) {
  return new Promise((resolve, reject) => {
    api
      .delete(`/v1/location/delete`, {
        data: payload,
      })
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function editLocation(payload) {
  return new Promise((resolve, reject) => {
    api
      .put(`/v1/location/update`, payload)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getRoles(companyId) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/getRoles?locale=${localStorage.getItem('applang')}`, {
        params: {
          companyId: companyId,
        },
      })
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getRolesAndPermissions(companyId) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/role/listAllPerms`, {
        params: {
          companyId: companyId,
        },
      })
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getSuggestedRoles() {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/suggestedRoles`, {
        params: {},
      })
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getPermissions() {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/permissions/list?locale=${localStorage.getItem('applang')}`)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function addSubsidiary(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(`/v1/company/createcompany`, payload)
      .then((result) => {
        return resolve(result.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getSubsidiaries(cid = null) {
  return new Promise((resolve, reject) => {
    api
      .get(
        cid
          ? `/v1/companySubsidaries?companyId=` +
              cid +
              `&locale=${localStorage.getItem('applang')}`
          : `/v1/companySubsidaries?locale=${localStorage.getItem('applang')}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function addRole(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(
        `/v1/rolePermissions?locale=${localStorage.getItem('applang')}`,
        payload,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function addRolesBulk(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(`/v1/defaultRolesToCompany`, payload)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function editRole(payload) {
  return new Promise((resolve, reject) => {
    api
      .put(
        `/v1/rolePermissions?locale=${localStorage.getItem('applang')}`,
        payload,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function deleteRole(payload) {
  return new Promise((resolve, reject) => {
    api
      .delete(`/v1/role/delete?locale=${localStorage.getItem('applang')}`, {
        data: payload,
      })
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getConfigs() {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/config/list`)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function getConfigsEligibleForRules(companyId) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/config/ruleApplicable?companyId=${companyId}`)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function getAllConfigs(id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/config/getAllTypes`, {
        params: { companyId: id, locale: localStorage.getItem('applang') },
      })
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function getConfig(configName, id) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/config/getAllTypes`, {
        params: { companyId: id, locale: localStorage.getItem('applang') },
      })
      .then((result) => {
        const data = result.data.data;
        const f = data[configName];
        return resolve(f);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function getConfigTypeById(id, comp_ID, leavetype = null) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/config/typesById?locale=${localStorage.getItem('applang')}`, {
        params: { id: id, companyId: comp_ID, leavetype: leavetype },
      })
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function getConfigType(comp_ID, typeId = null) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v2/config/configs?locale=${localStorage.getItem('applang')}`, {
        params: {companyId: comp_ID, configId: typeId },
      })
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function addConfigType(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(
        `/v1/config/type?locale=${localStorage.getItem('applang')}`,
        payload,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function editConfigType(payload) {
  return new Promise((resolve, reject) => {
    api
      .put(`/v1/config/type?locale=${localStorage.getItem('applang')}`, payload)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function deleteConfigType(payload) {
  return new Promise((resolve, reject) => {
    api
      .delete(`/v1/config/type?locale=${localStorage.getItem('applang')}`, {
        data: payload,
      })
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function addRuleEngine(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(`/v1/engine/rule`, payload)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function getRuleForType(companyId, configId) {
  return new Promise((resolve, reject) => {
    api
      .get(`/v1/engine/rule?companyId=${companyId}&typeId=${configId}`)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function getModelNameForType(id, model) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/config/typesByModel?id=${id}&model=${model}&locale=${localStorage.getItem(
          'applang',
        )}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
function getConfigData(companyId, model, payoutypeID) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/config/typesByModelName?company_id=${companyId}&model=${model}&bankexctype=${payoutypeID}&locale=${localStorage.getItem(
          'applang',
        )}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function readMyNotifications(id, table, uid, cid) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v1/notification/updatereadat?companyID=${cid}&uid=${uid}&id=${id}&table=${table}`,
      )
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function getMfaOptionList() {
  return new Promise((resolve, reject) => {
    api
      .get(`/v2/config/get-mfa-option-list`)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function addMfaConfigs(payload) {
  return new Promise((resolve, reject) => {
    api
      .post(
        `/v2/config/save-mfa?locale=${localStorage.getItem('applang')}`,
        payload,
      )
      .then((result) => {
        return resolve(result?.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function getMfaConfigList(companyId) {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/v2/config/mfa-list?company_id=${companyId}&locale=${localStorage.getItem('applang')}`,
      )
      .then((result) => {
        return resolve(result?.data?.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

function deleteMfaConfig(id) {
  return new Promise((resolve, reject) => {
    api
      .delete(`/v2/config/delete-mfa/${id}`)
      .then((result) => {
        return resolve(result.data.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}

export default organisation;

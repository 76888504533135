import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import File from '@mui/icons-material/InsertDriveFile';

import { DefaultAvatar } from '../../common/constants';
import { payloadValidator } from '../../helpers/payloadValidator';
import { CustomToast } from '../../common/Methodcommon';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
  borderRadius: '5px',
};

const accept = [
  '.pdf',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/bmp',
  'image/webp',
  'image/svg+xml',
].join(', ');

const isFile = (url) => {
  const extension = url.split('.').pop().toLowerCase();
  const fileTypeMap = {
    pdf: 'pdf',
    doc: 'doc',
    docx: 'doc',
    xls: 'xls',
    xlsx: 'xlsx',
  };
  return fileTypeMap[extension] ||
    Object.values(fileTypeMap).some((fileType) => extension.includes(fileType))
    ? true
    : false;
};

export function FileInput({
  name,
  className,
  files,
  handleFiles,
  handleDownload,
  placeholder,
  fileClassName,
  disabled = false,
}) {
  const { t } = useTranslation();
  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop: (acceptedFiles) => {
      handleUploadFile(
        Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0]),
        }),
        name,
      );
    },
    disabled,
    noClick: disabled,
  });

  const thumbs = (
    <div
      className={`box-border inline-flex w-[120px] h-[120px] justify-center ${fileClassName}`}
    >
      <div style={thumbInner} className="items-center">
        {files ? (
          typeof files === 'string' ? (
            isFile(files) ? (
              <File
                style={{
                  width: 75,
                  height: 75,
                }}
                className="text-primary"
              />
            ) : (
              <img
                src={files}
                style={img}
                alt={name}
                className="object-fill"
                onLoad={() => {
                  URL.revokeObjectURL(files?.preview);
                }}
              />
            )
          ) : files.type.startsWith('image/') ? (
            <img
              src={files.preview}
              style={img}
              alt={name}
              className="object-fill"
              onLoad={() => {
                URL.revokeObjectURL(files?.preview);
              }}
            />
          ) : (
            <File
              style={{
                width: 75,
                height: 75,
              }}
              className="text-primary"
            />
          )
        ) : (
          <img
            src={DefaultAvatar}
            style={img}
            alt={name}
            className="object-fill"
          />
        )}
      </div>
    </div>
  );

  useEffect(() => {
    return () => URL.revokeObjectURL(files?.preview);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUploadFile = (files, name) => {
    const validate = payloadValidator(files);
    if (validate.isValid) handleFiles(files, name);
    else CustomToast('error', validate.message);
  }

  return (
    <>
      <div className='flex flex-start'>
        <div
          {...getRootProps({
            className: `dropzone relative flex justify-center items-center mt-0 w-[130px] h-[125px] rounded-md border border-gray-300 border-dashed ${className} ${!disabled ? 'cursor-pointer' : ''
              }`,
          })}
        >
          <input {...getInputProps()} />
          <>
            {!files ? (
              placeholder ?? (
                <p className="text-gray-500 text-sm">{t('Attach File')}</p>
              )
            ) : (
              <>
                {handleDownload ? (
                  <div className="absolute bottom-0 -right-2 z-20 cursor-pointer">
                    <i
                      className="las la-download"
                      style={{
                        color: 'red',
                        cursor: 'pointer',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDownload();
                      }}
                    ></i>
                  </div>
                ) : null}
                {handleFiles ? (
                  <div
                    className="absolute -top-2 px-2 py-[3px] rounded-full flex justify-center items-center bg-primary-light -right-2 z-20 text-xs"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFiles(null, name);
                    }}
                  >
                    ×
                  </div>
                ) : null}
                <aside
                  style={thumbsContainer}
                  className="relative items-center justify-center"
                >
                  {thumbs}
                </aside>
              </>
            )}
          </>
        </div>
        <div>
          {files?.name && files?.size && (
            <>
            <p className="text-gray-500 text-sm  ms-2 mt-[30px]"><span className='text-black mx-1'>FileName:</span>{files.name}</p>
            <p className="text-gray-500 text-sm  ms-2 mb-[30px]"><span className='text-black mx-1'>FileSize:</span>{(files.size / (1024 * 1024)).toFixed(2) + "MB"}</p>
          </>)}
        </div>
      </div>
    </>
  );
}

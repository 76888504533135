/* eslint-disable no-redeclare */
import moment from 'moment';
import toast from 'react-hot-toast';
import $ from 'jquery';
import _ from 'lodash';
import Empty from '../pages/Empty';
import { fileUpload_company } from '../api/core';
export function changemainlang(newlang, fromlogin = false) {
  localStorage.setItem('applang', newlang);
  if (newlang === 'ar') {
    $('<style id="arabic_style">')
      .load('/resources/sass/translate.scss')
      .appendTo('head');
    if ($('#ltr_style')) {
      $('#ltr_style').remove();
    }
  } else {
    if ($('#arabic_style')) $('#arabic_style').remove();
    $('<style id="ltr_style">')
      .load('/resources/sass/ltrstyle.scss')
      .appendTo('head');
  }
  if (!fromlogin) {
    document.documentElement.setAttribute('lang', newlang);
    if ($('body').hasClass(newlang === 'en' ? 'ar' : 'en')) {
      $('body').removeClass(newlang === 'en' ? 'ar' : 'en');
      $('body').addClass(newlang);
    } else if (!$('body').hasClass(newlang)) $('body').addClass(newlang);
    document.documentElement.setAttribute(
      'dir',
      newlang === 'ar' ? 'rtl' : 'ltr',
    );
    document.dir = newlang === 'ar' ? 'rtl' : 'ltr';
  }
}
export function dateformat1(date, trans, utclocal = null) {
  // return as DD MMMM YYYY
  let locale = localStorage.getItem('applang');
  if (!locale || locale === '') locale = 'en';
  if (utclocal) {
    if (locale === 'en') return moment(date).utc().format('DD MMMM YYYY');
    else {
      const month = moment(date).utc().format('MMMM');
      return (
        moment(date).utc().format('DD') +
        ' ' +
        trans(month) +
        ' ' +
        moment(date).utc().format('YYYY')
      );
    }
  } else {
    if (locale === 'en') return moment(date).format('DD MMMM YYYY');
    else {
      const month = moment(date).format('MMMM');
      return (
        moment(date).format('DD') +
        ' ' +
        trans(month) +
        ' ' +
        moment(date).format('YYYY')
      );
    }
  }
}

export function dateformat2(date, trans, utclocal = null) {
  // return as DD MMM,YYYY
  let locale = localStorage.getItem('applang');
  if (!locale || locale === '') locale = 'en';
  if (locale === 'en') return moment(date).format('DD MMM, YYYY');
  else {
    const month = moment(date).format('MMM');
    return (
      moment(date).format('DD') +
      ' ' +
      trans(month) +
      ',' +
      moment(date).format('YYYY')
    );
  }
}

export function dateformat3(date, trans, utclocal = null) {
  // return as MMMM

  const month = moment(date).format('MMMM');
  return trans(month);
}

export function dateformat4(date, trans, utclocal = null) {
  // return as Monday, Mar 20, 2023
  let locale = localStorage.getItem('applang');
  if (!locale || locale === '') locale = 'en';
  if (locale === 'en') return moment(date).format('dddd, MMM DD, YYYY');
  else {
    const month = moment(date).format('MMMM');
    const day = moment(date).format('dddd');

    return (
      trans(day) +
      ', ' +
      trans(month) +
      ' ' +
      moment(date).format('DD') +
      ', ' +
      moment(date).format('YYYY')
    );
  }
}

export function dateformat5(date, trans, utclocal = null) {
  // return as ddd, MMM DD,YYYY
  let locale = localStorage.getItem('applang');
  if (!locale || locale === '') locale = 'en';
  if (locale === 'en') return moment(date).format('ddd, MMM DD, YYYY');
  else {
    const day = moment(date).format('ddd');
    const month = moment(date).format('MMM');
    return (
      trans(day) +
      ', ' +
      trans(month) +
      ' ' +
      moment(date).format('DD') +
      ', ' +
      moment(date).format('YYYY')
    );
  }
}

export function dateformat6(date) {
  // return as YYYY-MM-DD
  return moment(date).format('YYYY-MM-DD');
}
export function dateListFormat(date, format = 'YYYY-MM-DD') {
  // return as YYYY-MM-DD
  return moment(date).format(format);
}
export function time_format(time, trans) {
  // return AM | PM in EN & AR
  //DEMO_DATA:
  //8:30 =>  am
  //17:30 => pm
  let locale = localStorage.getItem('applang');
  if (!locale || locale === '') locale = 'en';
  if (locale === 'en') {
    return (
      moment(time, ['HH.mm']).format('hh:mm') +
      ' ' +
      moment(time, ['HH.mm']).format('a')
    );
  } else {
    const day = moment(time, ['HH.mm']).format('a');
    return moment(time, ['HH.mm']).format('hh:mm') + ' ' + trans(day);
  }
}
export function time_format2(time, trans) {
  // return AM | PM in EN & AR
  //DEMO_DATA:
  //8:30 =>  am
  //17:30 => pm
  let locale = localStorage.getItem('applang');
  if (!locale || locale === '') locale = 'en';
  if (locale === 'en') {
    return moment(time).format('hh:mm a');
  } else {
    const day = moment(time).format('a');
    return moment(time).format('hh:mm') + ' ' + trans(day);
  }
}
export function show_action_required(userInfo, activeUserRole, userRoleInfo) {
  if (userInfo?.isAdmin) {
    return true;
  }
  const has = _.find(activeUserRole?.acl, function (access) {
    return access?.screen_name === 'Action Required';
  });

  const has2 = _.map(userRoleInfo?.access_control, function (access_cont) {
    const roleScreenAccess = _.find(access_cont?.acl, function (access) {
      return access?.screen_name === 'Action Required';
    });
    return roleScreenAccess;
  }).filter((item) => item !== undefined && item !== null);

  return userInfo?.roles &&
    userInfo?.roles?.length === 1 &&
    userInfo?.roles?.includes('Super Admin')
    ? false
    : has || has2;
}

export const dateFormat = (date) => {
  return moment(date).format('DD MMM, YYYY');
};

export const arabicLocalization = {
  body: {
    noMatch: 'عذرًا، لا توجد بيانات متطابقة',
    toolTip: 'ترتيب',
  },
  pagination: {
    next: 'التالي',
    previous: 'السابق',
    rowsPerPage: 'عدد الصفوف لكل صفحة',
    displayRows: 'من',
  },
  toolbar: {
    search: 'بحث',
    downloadCsv: 'تحميل CSV',
    print: 'طباعة',
    viewColumns: 'عرض الأعمدة',
    filterTable: 'تصفية الجدول',
  },
  filter: {
    all: 'الكل',
    title: 'فلاتر',
    reset: 'إعادة تعيين',
  },
  viewColumns: {
    title: 'عرض الأعمدة',
    titleAria: 'إظهار/إخفاء الأعمدة',
  },
  selectedRows: {
    text: 'صفوف محددة',
    delete: 'حذف',
    deleteAria: 'حذف الصفوف المحددة',
  },
};

export const englishLocalization = {
  body: {
    noMatch: 'Sorry, no matching data.',
    toolTip: 'order',
  },
  pagination: {
    next: 'next',
    previous: 'previous',
    rowsPerPage: 'rows per page',
    displayRows: 'from',
  },
  toolbar: {
    search: 'Discuss',
    downloadCsv: 'upload csv',
    print: 'Print',
    viewColumns: 'column',
    filterTable: 'Filter table',
  },
  filter: {
    all: 'all',
    title: 'filters',
    reset: 'Reset',
  },
  viewColumns: {
    title: 'column',
    titleAria: 'Show/Hide Columns',
  },
  selectedRows: {
    text: 'specific rows',
    delete: 'Delete',
    deleteAria: 'delete selected rows',
  },
};

export const iconloader = {
  body: {
    noMatch: <Empty />,
  },
};
export const arabicTranslationDataGrid = {
  noRowsLabel: 'عذرًا، لا توجد سجلات مطابقة',
  rowsPerPage: 'عدد الصفوف لكل صفحة',
  MuiTablePagination: {
    labelRowsPerPage: 'عدد الصفوف لكل صفحة',

    labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} من ${count}`,
  },
};
export function MUIDatatableOption(
  locale,
  type = null,
  loader = null,
  pageparams = null,
  callback = null,
  callBackrow = null,
  handleColumnOrderChange = null,
) {
  switch (type) {
    case 'nopagination':
      return {
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        elevation: 0,
        filter: false,
        selectableRowsHeader: false,
        selectableRows: false,
        pagination: false,
        serverSide: true,
        onColumnSortChange: (changedColumn, direction) =>
          handleColumnOrderChange({
            col: changedColumn,
            direction: direction,
          }),
        textLabels: loader
          ? iconloader
          : locale === 'ar'
            ? arabicLocalization
            : englishLocalization,
      };
    case 'withsearch':
      return {
        download: true,
        print: false,
        viewColumns: false,
        elevation: 0,
        filter: false,
        selectableRowsHeader: false,
        selectableRows: false,
        searchPlaceholder: locale === 'ar' ? 'اكتب هنا...' : 'Type here...',
        textLabels: loader
          ? iconloader
          : locale === 'ar'
            ? arabicLocalization
            : englishLocalization,
      };
    case 'withpagination':
      return {
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        elevation: 0,
        filter: false,
        selectableRowsHeader: false,
        textLabels: loader
          ? iconloader
          : locale === 'ar'
            ? arabicLocalization
            : englishLocalization,
        selectableRows: false,
        onChangeRowsPerPage: (numberOfRows) => {
          callBackrow(numberOfRows);
        },
        serverSide: true,
        rowsPerPage: pageparams?.rowsPerPage,
        count: pageparams?.count,
        rowsPerPageOptions: pageparams?.rowsPerPageOptions,
        page: pageparams?.page - 1,
        onTableChange: (action, tableState) => {
          if (action === 'changePage') {
            if (callback) callback(tableState.page + 1);
          } else {
          }
        },
      };
    default:
      return {
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        elevation: 0,
        filter: false,
        selectableRowsHeader: false,
        selectableRows: false,
        textLabels: loader
          ? iconloader
          : locale === 'ar'
            ? arabicLocalization
            : englishLocalization,
      };
  }
}
export function CustomToast(status, message, duration) {
  switch (status) {
    case 'success':
      return toast.success(message, {
        duration,
      });
    case 'error':
      return toast.error(message, {
        duration,
      });
    case 'loading':
      return toast.loading(message, {
        duration,
      });
    default:
      break;
  }
}
export function uploadFileToCompanyFolder(e, event) {
  return new Promise((resolve, reject) => {
    let image_as_files = e.target.files[0];
    let file = image_as_files;
    const company_id = localStorage.getItem('__current_company_id');
    const url = '/public/uploads/' + company_id + '/' + event + '/' + file.name;
    const formData = new FormData();
    formData.append('event', event);
    formData.append('attachment', image_as_files);
    formData.append('name', file.name);
    formData.append('company_id', company_id);
    fileUpload_company(formData)
      .then((result) => {
        return resolve(url);
      })
      .catch((err) => {
        return reject(err);
      });
  });
}
export function check_permission(module, menu = null, type, userInfo) {
  var full_current_role = userInfo?.access_control;
  var current_role = [];
  if (full_current_role && full_current_role?.length > 0) {
    full_current_role?.forEach((element) => {
      if (element && element?.acl?.length > 0) {
        element?.acl?.forEach((lists) => {
          if (lists) {
            const itemexist =
              current_role?.length === 0
                ? -1
                : current_role?.findIndex(
                    (item) => item?.perm_id === lists?.perm_id,
                  );
            if (itemexist < 0) current_role.push(lists);
          }
        });
      }
    });
  }
  if (userInfo?.isAdmin) {
    if (type === 'boolean') return true;
    else return menu;
  }
  // eslint-disable-next-line default-case
  switch (module) {
    case 'employee_list':
      // employee list icon handling with permissions
      var admin_list = current_role?.filter(
        (e) => e?.perm_name === 'Employee Data Admin Access',
      );
      var read_only_list = current_role?.filter(
        (e) => e?.perm_name === 'Employee Data Read Only Access',
      );
      var restriction_list = current_role?.filter(
        (e) => e?.perm_name === 'Employee data restriction (edit) Access',
      );
      var export_list = current_role?.filter(
        (e) => e?.perm_name === 'Employee Data Bulk Import & Export Access',
      );
      var shift_update_access = current_role?.filter(
        (e) => e?.perm_name === 'Shift Update Access',
      );
      var location_list = current_role?.filter(
        (e) => e?.perm_name === 'Location Block List Access',
      );
      var action_access = current_role?.filter(
        (e) => e?.perm_name === 'Employee Data Action Access',
      );
      if (type === 'list') {
        if (admin_list?.length > 0) return menu;
        else if (read_only_list?.length > 0 || restriction_list?.length > 0)
          return ['view'];
        else return [];
      } else if (type === 'column') {
        if (
          !(
            admin_list?.length > 0 ||
            restriction_list?.length > 0 ||
            read_only_list?.length > 0
          )
        )
          return menu.filter((item) => item.name !== 'action');
        else return menu;
      } else if (type === 'boolean') {
        if (menu === 'admin') {
          if (admin_list?.length > 0) return true;
          else return false;
        } else if (menu === 'export_import') {
          if (admin_list?.length > 0 || export_list?.length > 0) return true;
          else return false;
        } else if (menu === 'action') {
          if (action_access?.length > 0) return true;
          else return false;
        }
      } else if (type === 'sub_menus') {
        if (admin_list?.length === 0)
          menu = menu.filter((item) => item.name !== 'Leave Allotment');
        if (!(location_list?.length === 1 || admin_list?.length === 1))
          menu = menu.filter((item) => item.name !== 'Location Block List');
        return menu;
      }

      break;
    case 'attendance_list':
      // employee list icon handling with permissions
      var admin_list = current_role?.filter(
        (e) => e?.perm_name === 'Attendance Admin Access',
      );
      var read_only_list = current_role?.filter(
        (e) => e?.perm_name === 'Attendance Read Only Access',
      );
      var restriction_list = current_role?.filter(
        (e) => e?.perm_name === 'Attendance restriction (edit) Access',
      );
      // eslint-disable-next-line no-redeclare
      var export_list = current_role?.filter(
        (e) => e?.perm_name === 'Attendance Bulk Import & Export Access',
      );
      var timeshift_list = current_role?.filter(
        (e) => e?.perm_name === 'Attendance Time Shift Access',
      );
      var weekoff_list = current_role?.filter(
        (e) => e?.perm_name === 'Attendance Week Off Access',
      );
      var biometric_log = current_role?.filter(
        (e) => e?.perm_name === 'Biometric Logs Access',
      );
         var shift_update_access = current_role?.filter(
        (e) => e?.perm_name === 'Shift Update Access',
      );

      if (type === 'list') {
        if (admin_list?.length > 0) return menu;
        else if (read_only_list?.length > 0 && restriction_list?.length > 0)
          return ['edit', 'view'];
        else if (read_only_list?.length > 0 && !restriction_list?.length > 0)
          return ['view'];
        else if (!read_only_list?.length > 0 && restriction_list?.length > 0)
          return ['edit'];
        else return [];
      } else if (type === 'column') {
        if (
          !(
            admin_list?.length > 0 ||
            restriction_list?.length > 0 ||
            read_only_list?.length > 0
          )
        )
          return menu.filter((item) => item.name !== 'action');
        else return menu;
      } else if (type === 'boolean') {
        if (menu === 'admin') {
          if (admin_list?.length > 0) return true;
          else return false;
        } else if (menu === 'export_import') {
          if (admin_list?.length > 0 || export_list?.length > 0) return true;
          else return false;
        } else if (menu === 'update_shift_access') {
          if (shift_update_access?.length > 0) return true;
          else return false;
        }
      } else if (type === 'sub_menus') {
        var time_index = menu?.findIndex((item) => item.title === 'Attendance');
        if (
          !(timeshift_list?.length === 1 || admin_list?.length === 1) &&
          time_index >= 0
        )
          menu[time_index]['subMenu'] = menu[time_index]['subMenu'].filter(
            (item) => item.title !== 'Time Shift',
          );
        if (
          !(weekoff_list?.length === 1 || admin_list?.length === 1) &&
          time_index >= 0
        )
          menu[time_index]['subMenu'] = menu[time_index]['subMenu'].filter(
            (item) => item.title !== 'Week Off',
          );
        if (
          !(biometric_log?.length === 1) &&
          time_index >= 0
        )
          menu[time_index]['subMenu'] = menu[time_index]['subMenu'].filter(
            (item) => item.title !== 'Biometric Logs',
          );
        return menu;
      }
      break;
  }
}
